import { FC, ReactNode } from "react";
import { Box, alpha, Container, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import Sidebar from "./Sidebar";
import Toggle from "./Header/Toggle";
import Breadcrumb from "src/components/common/Breadcrumb";
import PageHeader from "src/components/common/PageHeader";
import BottomNavigation from "./BottomNavigation";
import NetworkStatus from "src/components/common/NetworkStatus";
import Logo from "src/components/common/LogoSign";

interface SidebarLayoutProps {
	children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					flex: 1,
					height: "100%",
					".MuiPageTitle-wrapper": {
						background:
							theme.palette.mode !== "dark"
								? theme.colors.alpha.trueWhite[5]
								: theme.colors.alpha.white[50],
						marginBottom: `${theme.spacing(4)}`,
					},
				}}>
				{/* <Header /> */}
				<Box
					sx={{
						display: { lg: "none", xs: "flex" },
						alignItems: "center",
						justifyContent: "space-between",
						pr: 2,
					}}>
					<Toggle />
					<Box sx={{ mr: -2 }}>
						<Logo scale={0.7} />
					</Box>
				</Box>
				<Sidebar />
				<Box
					sx={{
						position: "relative",
						zIndex: 5,
						display: "block",
						flex: 1,
						pt: `${theme.spacing(0)}`,
						pb: { xs: `${theme.spacing(8)}`, lg: `${theme.spacing(2)}` },
						[theme.breakpoints.up("lg")]: {
							ml: `${theme.sidebar.width}`,
							pl: `20px`,
							pt: `${theme.spacing(4)}`,
						},
					}}>
					<Container maxWidth="lg">
						{/* <Box sx={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 100 }}> */}
							<NetworkStatus />
						{/* </Box> */}
						<Box display="block">
							<Breadcrumb />
							<PageHeader />
							<Outlet />
						</Box>
					</Container>
				</Box>
				<Box sx={{ display: { xs: "block", lg: "none" } }}>
					<BottomNavigation />
				</Box>
			</Box>
		</>
	);
};

export default SidebarLayout;
