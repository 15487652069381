import { useRoutes } from "react-router-dom";
import router from "src/router";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import { useEffect } from "react";
import Cookies from "js-cookie";

function App() {
	const content = useRoutes(router);
	const source = new URLSearchParams(location.search).get("source");
	const referral = new URLSearchParams(location.search).get("referral");

	useEffect(() => {
		if (source) {
			Cookies.set("source", source);
		}
		if (referral) {
			if (!Cookies.get("referral")) {
				Cookies.set("referral", referral);
			}
		}
	}, []);

	return (
		<ThemeProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<CssBaseline />
				{content}
			</LocalizationProvider>
		</ThemeProvider>
	);
}
export default App;
