export const dataBreadcrumb = {
	"/": [{ title: "Dashboard", link: "/" }],
	"/dashboards": [{ title: "Dashboard", link: "/" }],
    "/classes": [
        { title: "Kelas", link: "" },
    ],
    "/classes/e-course": [
        { title: "Kelas", link: "/classes" },
        { title: "e-course", link: "" },
    ],
    "/classes/tryout": [
        { title: "Kelas", link: "/classes" },
        { title: "tryout", link: "/classes/tryout" },
    ],
    "/classes/classroom": [
        { title: "Kelas", link: "/classes" },
        { title: "Class", link: "/classes/classroom" },
    ],
    "/others": [
        { title: "Lainnya", link: "" },
    ],
    "/others/reports": [
        { title: "Lainnya", link: "/others" },
        { title: "Pelaporan", link: "" },
    ],
    "/others/transactions": [
        { title: "Lainnya", link: "/others" },
        { title: "Transaksi", link: "" },
    ],
    "/others/account": [
        { title: "Lainnya", link: "/others" },
        { title: "Akun", link: "" },
    ],
};
