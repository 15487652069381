import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface QuestionsState {}

const initialState: QuestionsState = {};

export const questionsSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        setQuestions: (state, action: PayloadAction<QuestionsState>) => {
            return {...action.payload};
        }
    }
});

export const { setQuestions } = questionsSlice.actions;

export default questionsSlice.reducer;