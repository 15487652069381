import Logo from "src/components/common/LogoSign";
import {
	Box,
	Container,
	Button,
	Divider,
	Drawer,
	IconButton,
	Tooltip,
	Menu,
	MenuItem,
	Collapse,
	styled,
	useTheme,
} from "@mui/material";
import { logout } from "src/services/auth.service";
import UserBox from "src/components/common/UserBox";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import { NavbarContext } from "src/contexts/NavbarContext";
import { isLoggedIn, isParentLoggedIn } from "src/services/auth.service";
import { useContext, useState, useEffect } from "react";
import Link from "src/components/common/Link";
import { useLocation } from "react-router";
import { getMainProgramCategories } from "src/services/category";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboaddArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router";
import getUser from "src/models/User";
import getParent from "src/models/Parent";


const NavbarWrapper = styled(Box)(
	({ theme }) => `
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
		background-color: ${theme.colors.alpha.trueWhite[100]};
`
);

const ButtonRounded = styled(Button)(
	() => `
    border-radius: 50px;
    padding: 8px 25px;
    font-weight: bold;
`
);

const ButtonList = styled(Button)(
	() => `
	// justify-content: flex-start;

`
);

const CustomDrawer = styled(Drawer)(
	() => `
		.MuiDrawer-paper {
			position: initial;
		}
`
);

const Navbar = () => {
	const { navbarToggle, toggleNavbar } = useContext(NavbarContext);
	const closeNavbar = () => {
		setOpen(false);
		toggleNavbar();
	};
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const [openCategory, setOpenCategory] = useState([]);
	const [mainProgramCategories, setMainProgramCategories] = useState([]);

	useEffect(() => {
		getMainProgramCategories().then((res) => {
			setMainProgramCategories(res.data.data);
			setOpenCategory(res.data.data.map((category) => false));
		});
	}, []);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpen(!open);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClickCategory = ({ rootIndex, index, categoryId }) => {
		navigate(
			`/programs?rootIndex=${rootIndex}&index=${index}&categoryId=${categoryId}`
		);
		closeNavbar();
	};

	const NavButton = ({ to, label, location, onClick, endIcon = null }) => {
		const isActive = location.pathname === to;
		const fontWeight = isActive ? "900" : "500";
		const backgroundColor = isActive
			? theme.colors.primary.main
			: "transparent";
		const color = isActive ? theme.colors.alpha.trueWhite[100] : "";

		return (
			<Link to={to} onClick>
				<ButtonList
					variant="text"
					color="primary"
					fullWidth
					endIcon={endIcon}
					style={{ fontWeight, backgroundColor, color }}>
					{label}
				</ButtonList>
			</Link>
		);
	};

	return (
		<>
			<NavbarWrapper
				sx={{
					borderBottom: "1px solid #D9DBE9",
					display: {
						xs: "none",
						lg: "inline-block",
					},
					opacity: 0.85,
				}}>
				{/* Desktop View */}
				<Container maxWidth="lg">
					<Box
						display="flex"
						justifyContent="space-between"
						py={0}
						alignItems="center">
						<Logo />
						<Box display="flex" alignItems="center">
							<NavButton to="/" label="Home" location={location} onClick="" />
							<NavButton
								to="/programs"
								label="Programs"
								location={location}
								onClick=""
							/>
							<NavButton
								to="/testimony"
								label="Testimonies"
								location={location}
								onClick=""
							/>
							<NavButton
								to="/blogs"
								label="News"
								location={location}
								onClick=""
							/>
							<a href="#footer">
								<ButtonList
									variant="text"
									color="primary"
									style={{ fontWeight: "500" }}>
									Contact
								</ButtonList>
							</a>
							{isParentLoggedIn() && getParent() && (
								<NavButton
									to={`/report/member/${getParent().token}`}
									label="Report"
									location={location}
									onClick=""
								/>
							)}
						</Box>
						{isLoggedIn() ? (
							<Box sx={{ my: 0.8 }}>
								<UserBox />
							</Box>
						) : (
							<Box sx={{ my: 2 }}>
								<Link to="/login">
									<ButtonRounded
										variant="outlined"
										color="primary"
										sx={{ mr: 2 }}>
										Masuk
									</ButtonRounded>
								</Link>
								<Link to="/auth/register">
									<ButtonRounded
										variant="contained"
										color="primary"
										sx={{ mr: 2 }}>
										Daftar
									</ButtonRounded>
								</Link>
							</Box>
						)}
					</Box>
				</Container>
			</NavbarWrapper>
			<NavbarWrapper
				sx={{
					// borderBottom: "1px solid #D9DBE9",
					display: {
						xs: "inline-block",
						lg: "none",
					},
					px: 1,
					py: 1.4,
					background: "transparent",
				}}>
				{/* Mobile View */}
				<Container maxWidth="lg" sx={{ px: 0.5 }}>
					<Box
						display="flex"
						justifyContent="space-between"
						py={0.5}
						style={{
							borderRadius: "28px",
							border: "1px solid #eee",
							boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
							backgroundColor: `${theme.colors.alpha.trueWhite[100]}`,
							opacity: 0.9,
						}}
						alignItems="center">
						<Box sx={{ ml: -0.8, mb: 0.6 }}>
							<Logo scale={0.7} />
						</Box>
						<Tooltip arrow title="Lihat Menu">
							<Box style={{ marginTop: 1.4 }}>
								<IconButton color="primary" onClick={closeNavbar}>
									{!navbarToggle ? (
										<img src="/static/images/icons/hamburger.svg" alt="menu" />
									) : (
										<CloseTwoToneIcon fontSize="small" />
									)}
								</IconButton>
							</Box>
						</Tooltip>
					</Box>
				</Container>
			</NavbarWrapper>
			<CustomDrawer
				sx={{
					boxShadow: `${theme.sidebar.boxShadow}`,
				}}
				anchor="left"
				open={navbarToggle}
				onClose={closeNavbar}
				variant="temporary"
				elevation={0}>
				<NavbarWrapper
					sx={{
						borderBottom: "1px solid #D9DBE9",
						display: {
							xs: "inline-block",
							lg: "none",
						},
					}}>
					<Container maxWidth="lg">
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								pb: 2,
							}}>
							<Box
								display="flex"
								justifyContent="space-between"
								py={2}
								sx={{
									width: "100%",
								}}
								alignItems="center">
								<Box sx={{ ml: -2 }}>
									<Logo scale={0.7} />
								</Box>
								<Tooltip arrow title="Lihat Menu">
									<IconButton color="primary" onClick={closeNavbar}>
										{!navbarToggle ? (
											<MenuTwoToneIcon fontSize="small" />
										) : (
											<CloseTwoToneIcon fontSize="small" />
										)}
									</IconButton>
								</Tooltip>
							</Box>
							<Divider
								sx={{
									mt: theme.spacing(1),
									mx: theme.spacing(2),
									background: theme.colors.alpha.trueWhite[10],
								}}
							/>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "left",
									width: "100%",
								}}>
								<NavButton
									to="/"
									label="Home"
									location={location}
									onClick={closeNavbar}
								/>
								<ButtonList
									id="basic-button"
									aria-controls="basic-menu"
									aria-haspopup="true"
									aria-expanded={open ? "true" : undefined}
									onClick={handleClick}
									endIcon={
										location.pathname === "/programs" ? (
											open ? (
												<KeyboaddArrowUpIcon />
											) : (
												<KeyboardArrowDownIcon />
											)
										) : null
									}
									variant={
										location.pathname === "/programs" ? "contained" : "text"
									}
									style={{ width: "100%" }}>
									Programs
								</ButtonList>
								<Collapse in={open} timeout="auto" unmountOnExit>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "left",
											width: "100%",
										}}>
										{mainProgramCategories.map((category, index) => (
											<Box
												key={category.id}
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "left",
													width: "100%",
												}}>
												<ButtonList
													variant="text"
													color="primary"
													endIcon={
														openCategory[index] ? (
															<KeyboaddArrowUpIcon />
														) : (
															<KeyboardArrowDownIcon />
														)
													}
													onClick={() => {
														let temp = [...openCategory];
														temp = temp.map((item, i) => {
															if (i === index) {
																return !item;
															} else {
																return false;
															}
														});
														setOpenCategory(temp);
													}}
													style={{
														justifyContent: "space-between",
														fontWeight: "500",
														backgroundColor: openCategory[index]
															? theme.colors.primary.lighter
															: "transparent",
													}}>
													{category.name}
												</ButtonList>
												{category.children.map((category, index2) => (
													<Collapse
														in={openCategory[index]}
														timeout="auto"
														key={category.id}
														unmountOnExit>
														<Box
															key={category.id}
															sx={{
																display: "flex",
																flexDirection: "column",
																alignItems: "left",
																width: "100%",
															}}>
															<ButtonList
																variant="text"
																color="primary"
																style={{
																	justifyContent: "flex-start",
																	paddingLeft: "40px",
																}}
																onClick={() =>
																	handleClickCategory({
																		rootIndex: index,
																		index: index2,
																		categoryId: category.id,
																	})
																}>
																- {category.name}
															</ButtonList>
														</Box>
													</Collapse>
												))}
											</Box>
										))}
									</Box>
								</Collapse>
								<NavButton
									to="/testimony"
									label="Testimonies"
									location={location}
									onClick={closeNavbar}
								/>
								<NavButton
									to="/blogs"
									label="News"
									location={location}
									onClick={closeNavbar}
								/>
								<a
									href="#footer"
									onClick={closeNavbar}
									style={{ width: "100%" }}>
									<ButtonList
										variant="text"
										color="primary"
										style={{ width: "100%" }}>
										Contact
									</ButtonList>
								</a>
								{isParentLoggedIn() && getParent() && (
									<NavButton
										to={`/report/member/${getParent().token}`}
										label="Report"
										location={location}
										onClick={closeNavbar}
										/>
								)}
							</Box>
							<Divider variant="middle" />
							{isLoggedIn() ? (
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "left",
										borderTop: "1px solid #D9DBE9",
										width: "100%",
										pt: 2,
									}}>
									<ButtonRounded
										variant="text"
										color="primary"
										onClick={closeNavbar}
										startIcon={<AccountTreeTwoToneIcon />}
										sx={{ mb: 1 }}>
										<Link to="/dashboards">E-Learning</Link>
									</ButtonRounded>
									<ButtonRounded
										variant="contained"
										color="primary"
										onClick={() => {
											closeNavbar();
											logout();
										}}>
										Logout
									</ButtonRounded>
								</Box>
							) : (
								<Box display="flex" alignItems="center">
									<Link to="/login" onClick={closeNavbar}>
										<ButtonRounded
											variant="outlined"
											color="primary"
											sx={{ mr: 1 }}>
											Login
										</ButtonRounded>
									</Link>
									<Link to="/auth/register" onClick={closeNavbar}>
										<ButtonRounded variant="contained" color="primary">
											Register
										</ButtonRounded>
									</Link>
								</Box>
							)}
						</Box>
					</Container>
				</NavbarWrapper>
			</CustomDrawer>
		</>
	);
};

export default Navbar;
