export const getIsExample = () => {
	const url = window.location.href;
	const urlWithoutFragment = url.split("#")[0];
	const urlArray = urlWithoutFragment.split("/");
	const lastUrl = urlArray[urlArray.length - 1]; // get the last url

	const isExample = lastUrl.includes("start"); // check if the last url is "start"
	return isExample;
};

export const getTryoutParam = () => {
	return JSON.parse(localStorage.getItem(`TRYOUT`) || null);
};

export const removeTryoutParam = () => {
	localStorage.removeItem(`TRYOUT`);
};

const removeFirstLetter = (text: string) => {
	return text.slice(1);
};

export const showCategory = (category: string) => {
	if (category) {
		if (category[0].match(/[0-9]/)) {
			return removeFirstLetter(category);
		}
	}
	return category;
};
