import { useContext } from "react";
import Scrollbar from "src/components/common/Scrollbar";
import { SidebarContext } from "src/contexts/SidebarContext";
import Logo from "src/components/common/LogoSign";
import {
	Box,
	Drawer,
	alpha,
	styled,
	Divider,
	useTheme,
	lighten,
	darken,
} from "@mui/material";
import SidebarMenu from "./SidebarMenu";
import UserBox from "src/components/common/UserBox";
import { isLoggedIn } from "src/services/auth.service";

const SidebarWrapper = styled(Box)(
	({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

function Sidebar() {
	const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
	const closeSidebar = () => toggleSidebar();
	const theme = useTheme();

	return (
		<>
			<SidebarWrapper
				sx={{
					display: {
						xs: "none",
						lg: "inline-block",
					},
					position: "fixed",
					left: 0,
					top: 0,
					background:
						theme.palette.mode !== "dark"
							? alpha(lighten(theme.header.background, 0.1), 0.5)
							: darken(theme.colors.alpha.black[100], 0.5),
					boxShadow:
						theme.palette.mode !== "dark" ? theme.sidebar.boxShadow : "none",
				}}>
				<Scrollbar>
					<Box
						pt={2}
						pl={2}
						pb={2}
						mx={1}
						sx={{
							width: 265,
							position: "fixed",
							zIndex: 999,
							background: "#F9FAFC",
							boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
							padding: "22px 34px",
						}}>
						<Logo />
					</Box>
					<Box pt={10}>{isLoggedIn() && <UserBox />}</Box>
					<Divider
						sx={{
							mt: theme.spacing(3),
							mx: theme.spacing(2),
							background: theme.colors.alpha.trueWhite[10],
						}}
					/>
					<SidebarMenu />
				</Scrollbar>
				<Divider
					sx={{
						background: theme.colors.alpha.trueWhite[10],
					}}
				/>
			</SidebarWrapper>
			<Drawer
				sx={{
					boxShadow: `${theme.sidebar.boxShadow}`,
				}}
				anchor={theme.direction === "rtl" ? "right" : "left"}
				open={sidebarToggle}
				onClose={closeSidebar}
				variant="temporary"
				id="sidebar-drawer"
				elevation={9}>
				<SidebarWrapper
					sx={{
						background:
							theme.palette.mode !== "dark"
								? theme.colors.alpha.white[100]
								: darken(theme.colors.alpha.black[100], 0.5),
					}}>
					<Scrollbar>
						<Box
							pt={2}
							pl={2}
							pb={2}
							mx={1}
							sx={{
								width: 265,
								position: "fixed",
								zIndex: 999,
								background: "#F9FAFC",
								boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
								padding: "22px 34px",
							}}>
							<Logo />
						</Box>
						<Box mt={10}>
							<Box
								mx={1}
								sx={{
									width: 52,
								}}>
								{isLoggedIn() && <UserBox />}
							</Box>
						</Box>
						<Divider
							sx={{
								mt: theme.spacing(3),
								mx: theme.spacing(2),
								background: theme.colors.alpha.trueWhite[10],
							}}
						/>
						<SidebarMenu />
					</Scrollbar>
				</SidebarWrapper>
			</Drawer>
		</>
	);
}

export default Sidebar;
