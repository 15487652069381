import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import styled from "@mui/material/styles/styled";
import { useNavigate } from "react-router";
import { dataBreadcrumb } from "./data";

const CustomLink = styled(Link)(
	({ theme }) => `
    &:hover {
      cursor: pointer;
      color: ${theme.colors.primary.main};
    }
`
);

export default function Breadcrumb() {
	const navigate = useNavigate();
	const handleClick = (link) => {
		navigate(link);
	};

	const data = dataBreadcrumb[window.location.pathname];
	const breadcrumbs = [
		data &&
			data.map((item, index) => {
				return (
					<CustomLink
						underline={index === data.length - 1 ? "none" : "hover"}
						key={index}
						color="inherit"
						onClick={() => {
							handleClick(item.link);
						}}>
						{item.title}
					</CustomLink>
				);
			}),
	];

	return (
		<>
			{data && (
				<Stack spacing={2}>
					<Breadcrumbs separator="›" aria-label="breadcrumb">
						{breadcrumbs}
					</Breadcrumbs>
				</Stack>
			)}
		</>
	);
}
