import ReactDOM from "react-dom";
import { HelmetProvider, Helmet } from "react-helmet-async"; // Import Helmet along with HelmetProvider
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./utils/Firebase";
import "nprogress/nprogress.css";
import "./global.css";
import App from "src/App";
import store from "./app/store";
import { Provider } from "react-redux";
import { SidebarProvider } from "src/contexts/SidebarContext";
import { NavbarProvider } from "./contexts/NavbarContext";
import * as serviceWorker from "src/serviceWorker";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
	<HelmetProvider>
		{/* Use Helmet to add the HackTimer scripts */}
		<Helmet>
			<meta
				name="google-site-verification"
				content="-xsj9gGnFvOoQhtonWWPfmR9nNmRVwAZ5M8dAEzVu80"
			/>
			<script src="https://cdn.jsdelivr.net/npm/hacktimer@latest/HackTimer.silent.min.js" />
			<script src="https://cdn.jsdelivr.net/npm/hacktimer@latest/HackTimerWorker.min.js" />

			{/* <!-- Google tag (gtag.js) --> */}
			<script
				async
				src="https://www.googletagmanager.com/gtag/js?id=AW-821545576"
			/>
			<script>
				{`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'AW-821545576');`}
			</script>
		</Helmet>

		<Provider store={store}>
			<SidebarProvider>
				<NavbarProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</NavbarProvider>
			</SidebarProvider>
		</Provider>
	</HelmetProvider>,
	document.getElementById("root")
);

serviceWorker.unregister();
