import { FC, ReactNode } from "react";
import { Box, Container, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Breadcrumb from "src/components/common/Breadcrumb";
import PageHeader from "src/components/common/PageHeader";
import BottomNavigation from "./BottomNavigation";
import NetworkStatus from "src/components/common/NetworkStatus";
import Logo from "src/components/common/LogoSign";

interface TentorLayoutProps {
	children?: ReactNode;
}

const TentorLayout: FC<TentorLayoutProps> = () => {
	const theme = useTheme();

	return (
		<>
			<Box
				sx={{
					flex: 1,
					height: "100%",
					".MuiPageTitle-wrapper": {
						background:
							theme.palette.mode !== "dark"
								? theme.colors.alpha.trueWhite[5]
								: theme.colors.alpha.white[50],
						marginBottom: `${theme.spacing(4)}`,
					},
				}}>
				<Box
					sx={{
						display: { lg: "none", xs: "flex" },
						alignItems: "center",
						justifyContent: "space-between",
						pr: 2,
					}}>
					<Box sx={{ mr: -2 }}>
						<Logo scale={0.7} />
					</Box>
				</Box>
				<Container maxWidth="md" sx={{ pb: "90px" }}>
					<NetworkStatus />
					<Box display="block">
						<Breadcrumb />
						<PageHeader />
						<Outlet />
					</Box>
				</Container>
				<Box sx={{ display: { xs: "block" } }}>
					<BottomNavigation />
				</Box>
			</Box>
		</>
	);
};

export default TentorLayout;
