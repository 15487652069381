import { axiosInstance } from "./axiosInstance";

export const getCategories = async (filter: any) => {
    filter = Object.keys(filter).map(function (key) {
        return key + '=' + filter[key];
    }).join('&');
    return await axiosInstance.get(`/api/feature/category?${filter}`);
}

export const getMainProgramCategories = async () => {
    return await axiosInstance.get(`/api/feature/category/get-main-program-category`);
}