import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AnsweredQuestionState {
    answerText: string[];
    selectedChoice: number[];
    answered: number[];
    duration: number[];
}

const initialState: AnsweredQuestionState = {
    answerText: [], /* answer text based on the index, for fill in the blanks and sentence arrangement */
    selectedChoice: [], /* selected choice id based on the index, for multiple choice*/
    answered : [], /* list of answered question index */
    duration: [], /* time taken to answer the question */
}

export const answeredQuestionSlice = createSlice({
    name: 'answeredQuestion',
    initialState,
    reducers: {
        setAnsweredQuestion: (state, action: PayloadAction<AnsweredQuestionState>) => {
            return {...action.payload};
        }
    }
});

export const { setAnsweredQuestion } = answeredQuestionSlice.actions;

export default answeredQuestionSlice.reducer;