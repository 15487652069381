import { FC } from "react";
import PropTypes from "prop-types";
import { Typography, Button, Grid, styled } from "@mui/material";

const GridContainer = styled(Grid)(
	({ theme }) => `
    margin-top: ${theme.spacing(3)};
`
);

interface PageTitleProps {
	heading?: string;
	subHeading?: string;
	docs?: string;
	buttons?: any;
	disabled?: boolean;
}

const PageTitle: FC<PageTitleProps> = ({
	heading = "",
	subHeading = "",
	docs = "",
	buttons = [],
	...rest
}) => {
	return (
		<GridContainer
			container
			justifyContent="space-between"
			alignItems="center"
			{...rest}>
			<Grid item xs={12} md={8}>
				<Typography variant="h3" component="h3" gutterBottom>
					{heading}
				</Typography>
				<Typography variant="subtitle2">{subHeading}</Typography>
			</Grid>
			<Grid item>
				{buttons &&
					buttons.map((item, index) => {
						return (
							<Button
								key={index}
								sx={{ mt: { xs: 2, md: 0 }, ml: { md: 2 }, mr: { xs: 1, md: 0 } }}
								variant={item.isContained ? "contained" : "outlined"}
								disabled={item.disabled}
								onClick={item.onclick}>
								{item.title}
							</Button>
						);
					})}
			</Grid>
		</GridContainer>
	);
};

PageTitle.propTypes = {
	heading: PropTypes.string,
	subHeading: PropTypes.string,
	docs: PropTypes.string,
};

export default PageTitle;
