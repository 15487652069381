import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';


export interface QuestionState {
    index: number;
    id: number;
    question: string;
    audio_source: string;
    type: string;
    categoryTitle: string;
    choices: any;
    selectedOption: string;
    answerText: string[];
    duration: number;
}

const initialState: QuestionState = {
    index: 0,
    id: 0,
    question: "",
    audio_source: "",
    type: "",
    categoryTitle: "",
    choices: [],
    selectedOption: "",
    answerText: [],
    duration: 0,
};

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        setQuestion: (state, action: PayloadAction<QuestionState>) => {
            console.log(action.payload, "payload question");
            state.index = action.payload.index;
            state.id = action.payload.id;
            state.question = action.payload.question;
            state.audio_source = action.payload.audio_source;
            state.type = action.payload.type;
            state.categoryTitle = action.payload.categoryTitle;
            state.choices = action.payload.choices;
            state.selectedOption = action.payload.selectedOption;
            state.answerText = action.payload.answerText;
            state.duration = action.payload.duration;
        },
    }
});

export const { setQuestion } = questionSlice.actions;

export default questionSlice.reducer;