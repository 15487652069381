import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Navbar from "src/components/home/Navbar";
import Footer from "src/components/home/Footer";

import { Container, Box } from "@mui/material";
import { AddBoxSharp } from "@mui/icons-material";

interface BlogLayoutProps {
	children?: ReactNode;
}

const BlogLayout: FC<BlogLayoutProps> = ({ children }) => {
	return (
		<Box sx={{ backgroundColor: "white", height: "100vh" }}>
			<Navbar />
			<Box sx={{ position: "relative", marginTop: "0"  }}>
				{children || <Outlet />}
			</Box>
			{/* <Footer /> */}
		</Box>
	);
};

BlogLayout.propTypes = {
	children: PropTypes.node,
};

export default BlogLayout;
