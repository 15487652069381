import "./style.css";

const LogoMini: React.FC = () => {
	return (
		<div style={{ backgroundColor: "white", borderRadius: "50%", padding: "2px" }}>
			<div className="spinAnimation">
				<img
					src="/android-chrome-192x192.png"
					alt="Brofesional logo"
					width="50px"
				/>
			</div>
		</div>
	);
};

export default LogoMini;
