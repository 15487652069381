import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CourseState {}

const initialState: CourseState = {};

export const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        setCourse: (state, action: PayloadAction<CourseState>) => {
            return {...action.payload};
        }
    }
});

export const { setCourse } = courseSlice.actions;

export default courseSlice.reducer;