// import React from "react";
import { Link as L } from "react-router-dom";

const Link = ({ to, children, ...rest }) => {
	return (
		<L
			to={to}
			{...rest}
			style={{ textDecoration: "none", color: "inherit" }}>
			{children}
		</L>
	);
};

export default Link;