export const getQuizParam = () => {
	return JSON.parse(localStorage.getItem(`QUIZ`) || null);
};

export const removeQuizParam = () => {
	localStorage.removeItem(`QUIZ`);
}

export const getLinkBackToCourse = (quizParam: any) => {
	if (quizParam.classId) {
		return `/classes/classroom/${quizParam.classId}/${quizParam.courseId}/${quizParam.materiId}/${quizParam.submaterialId}?quiz=${quizParam.Quiz}`;
	}
	return `/classes/e-course/${quizParam.membershipId}/${quizParam.courseId}/${quizParam.materiId}/${quizParam.submaterialId}?quiz=${quizParam.Quiz}`;
};
