import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import WifiOffIcon from '@mui/icons-material/WifiOff';

const NetworkStatus = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		const handleOnline = () => setIsOnline(true);
		const handleOffline = () => setIsOnline(false);

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		// Cleanup event listeners on component unmount
		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	return (
		<>
			{!isOnline && (
				<Box
					style={{
						padding: "10px",
						backgroundColor: "red",
						color: "white",
						display: "flex",
						alignItems: "center",
						borderRadius: "5px",
                        opacity: 0.95,

                        position: "fixed",
                        top: 16,
                        width: "200px",
					}}>
					<WifiOffIcon style={{ marginRight: 8 }} />
					You are offline
				</Box>
			)}
		</>
	);
};

export default NetworkStatus;
