export const dataTitle = {
    "/": {
        heading: "Overview",
    },
	"/dashboards": {
		heading: "Overview",
	},
	"/classes/e-course": {
		heading: "E-Course",
	},
	"/classes/tryout": {
		heading: "Try Out",
	},
	"/classes/classroom": {
		heading: "Class",
	},
	"/others/reports": {
		heading: "Daftar soal yang Anda laporkan",
	},
	"/others/transactions": {
		heading: "Transaksi",
	},
	"/others/account": {
		heading: "Account",
	},   
};
