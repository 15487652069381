import { axiosInstance } from "./axiosInstance";
import { getUserAgent } from "src/utils/Browser";
import getUser from "src/models/User";

export const postErrorLog = async (error: any) => {
	const url = window.location.pathname;
	const description = JSON.stringify(error?.response?.data?.message ?? error?.response?.data ?? error?.response?.statusText ?? error?.response?.status ?? error?.message ?? error?.stack ?? "Unknown error");
    let trace = JSON.stringify(error?.response) ?? "Unknown error";
	trace = trace + "\n\n" + JSON.stringify(getUserAgent());

    const data = new FormData();
	data.append("url", url);
	data.append("description", description);
	data.append("user_id", getUser()?.id);
    data.append("trace", trace);
	return await axiosInstance.post(`/api/feature/bug-logger/`, data);
};

export const customErrorLog = async (description: string, trace: string) => {
	const url = window.location.pathname;
	const data = new FormData();
	data.append("url", url);
	data.append("description", description);
	data.append("user_id", getUser()?.id);
	data.append("trace", trace);
	return await axiosInstance.post(`/api/feature/bug-logger/`, data);
}