import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SubMaterialVideoState {
    isDone: boolean;
}

const initialState: SubMaterialVideoState = {
    isDone: false,
};

export const subMaterialVideoSlice = createSlice({
    name: 'subMaterialVideo',
    initialState,
    reducers: {
        setSubMaterialVideo: (state, action: PayloadAction<SubMaterialVideoState>) => {
            return { ...action.payload };
        }
    }
});

export const { setSubMaterialVideo } = subMaterialVideoSlice.actions;

export default subMaterialVideoSlice.reducer;