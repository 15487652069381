import { FC, useState, createContext } from 'react';
type NavbarContext = {
    navbarToggle: any;
    toggleNavbar: () => void;
    closeNavbar: () => void;
    };

export const NavbarContext = createContext<NavbarContext>(
    {} as NavbarContext
);

export const NavbarProvider: FC = ({ children }) => {
    const [navbarToggle, setNavbarToggle] = useState(false);
    const toggleNavbar = () => {
        setNavbarToggle(!navbarToggle);
    };
    const closeNavbar = () => {
        setNavbarToggle(false);
    };

    return (
        <NavbarContext.Provider
            value={{ navbarToggle, toggleNavbar, closeNavbar }}
        >
            {children}
        </NavbarContext.Provider>
    );
};