import PageTitle from "src/components/common/PageTitle";
import PageTitleWrapper from "src/components/common/PageTitleWrapper";
import { dataTitle } from "./data";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

const PageHeader = () => {
	const location = useLocation();
	const [data, setData] = useState<any>(null);
	const currentPath = location.pathname;

	useEffect(() => {
		const data = dataTitle[currentPath];
		setData(data);
	}, [currentPath]);
	return (
		<>
			{data && (
				<PageTitleWrapper>
					<PageTitle
						heading={data.heading}
                        subHeading={data.subHeading}
						docs="https://material-ui.com/components/buttons/"
						buttons={data.butons}
					/>
				</PageTitleWrapper>
			)}
		</>
	);
};

export default PageHeader;
